<template>
    <div>Esporta Tipi Costituzione</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Tipi Costituzioni", route: "/settings/tipi-costituzione/index" },
             { title: "Esporta Tipi Costituzione" }
         ]);
     }
 };
</script>
